import Link from 'next/link'
import { Button, Typography, Box, BoxProps, styled, useTheme } from '@mui/material'
import Meta from '@/components/common/Meta'
import { META_CONTENTS } from '@/v1/constants/seo'

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const Img = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  marginTop: theme.spacing(4)
}))

const Error404 = () => {
  const theme = useTheme()

  return (
    <>
      <Meta
        title={META_CONTENTS.not_found.title}
        keywords={META_CONTENTS.not_found.keywords}
        description={META_CONTENTS.not_found.description}
        noIndex
        noFollow
      />
      <Box className='content-center'>
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <BoxWrapper>
            <Typography variant='h4' sx={{ mb: 2 }}>
              Page Not Found :(
            </Typography>
            <Typography sx={{ mb: 6, color: 'text.secondary' }}>
              Oops! 😖 The requested URL was not found on this server.
            </Typography>
            <Button href='/' component={Link} variant='contained'>
              Back to Home
            </Button>
          </BoxWrapper>
          <Img width='500' alt='error-illustration' src={`/images/pages/page-misc-error-${theme.palette.mode}.png`} />
        </Box>
      </Box>
    </>
  )
}
export default Error404
